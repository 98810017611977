<template>
  <div class="phone-use-info">
    <div class="phone-head-public animate__animated animate__fadeIn">
      <img :src="$route.query.imgUrl" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <!-- <p>Hardware</p> -->
        <h5>
          <span class="line-l"></span>
          <span class="center">{{ $route.query.title }}</span>
          <span class="line-r"></span>
        </h5>
      </div>
      <p class="cnt" v-html="$route.query.cnt"></p>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
    
<style scoped>
.cnt {
  width: 60%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 28px;
  text-indent: 2em;
}
</style>
<style>
.phone-use-info .cnt img {
  width: 100%;
}
</style>